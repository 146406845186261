import React from 'react'
import {graphql} from 'gatsby'
import {css} from '@emotion/core'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Link from '../components/link'
import Container from '../components/container'
import {AllNames} from '../templates/archives'
import theme from '../../config/theme'
import {bpMaxMD, bpMaxSM} from '~lib/breakpoints'
import {rhythm} from '~lib/typography'

export default function Index({data: {allMdx}}) {
  const allArchives = {}
  allMdx.edges.forEach(({node}, i) => {
    if (node.frontmatter.keywords) {
      node.frontmatter.keywords.forEach(word => {
        if (!allArchives[word]) allArchives[word] = 0
        allArchives[word]++
      })
    }
  })

  return (
    <Layout headerColor={theme.colors.white}>
      <SEO />
      <Container
        css={css`
          margin-top: 20px;
          position: relative;
          padding-bottom: 0;
          background: var(--bgcolor-post);
          border-radius: 5px;
          padding: 40px 80px 60px 80px;
          margin-bottom: ${rhythm(1)};
          ${bpMaxMD} {
            padding: auto;
          }
          ${bpMaxSM} {
            border-radius: 0;
          }
          h2 {
            margin-bottom: ${rhythm(1.5)};
          }
        `}
        className="with-mark-link"
      >
        <div
          css={{
            marginTop: 20,
            fontStyle: 'italic',
          }}
        >
          <Link to="/blog">📖 分页查看</Link> / <Link to="/search">🔍 搜索文章内容</Link> /{' '}
          <Link to="/readings">📰 我最近的阅读</Link>
        </div>
        <h2>📃 全部文章</h2>
        {allMdx.edges.map(({node: post}) => (
          <div
            key={post.id}
            css={css`
              margin-bottom: 15px;
              display: flex;
              align-items: center;
              justify-content: spcae-between;
              justify-content: space-between;
              h3 {
                padding: 0;
                margin: 0;
                font-weight: normal;
                border-bottom: 1px solid #eee;
                :hover {
                  border-color: #888;
                }
              }
            `}
          >
            <span
              css={css`
                min-width: 0;
                overflow: hidden;
                flex: 1;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 15px;
              `}
            >
              <Link to={post.fields.slug} aria-label={`View ${post.frontmatter.title}`}>
                {post.frontmatter.title}
              </Link>
            </span>

            <div
              css={css`
                font-size: 14px;
                color: #8e8e8e;
              `}
            >
              <div>{post.fields.datetime}</div>
            </div>
          </div>
        ))}
        <div
          css={css`
            text-align: center;
            margin-bottom: 80px;
          `}
        >
          🗄 全部 {allMdx.edges.length} 篇文章
        </div>

        <AllNames
          css={css`
            padding: 0;
          `}
          allKeywords={allArchives}
          pathPrefix={'/keywords'}
          name={'关键词'}
        />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {
        frontmatter: {published: {ne: false}, unlisted: {ne: true}}
        fileAbsolutePath: {regex: "//content/blog//"}
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
            datetime
            description
            categories
            keywords
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            keywords
          }
        }
      }
    }
  }
`
